import React, { useEffect, useState } from 'react';

import classNames from 'classnames';

import styles from './formControls.module.scss';

const Checkbox = ({
        label,
        name,
        onChange,
        required,
        isValid,
        value
    }) => {
    const [inputValue, setValue] = useState(undefined === value ? false : value);

    useEffect(() => {
        onChange(inputValue);
    }, [inputValue]);

    return (
        <label
            className={classNames(styles.checkboxGroup, {
                [styles.invalidLabel]: !isValid
            })}
            htmlFor={name}
        >
            <input
                checked={value}
                id={name}
                type="checkbox"
                onChange={e => setValue(e.target.checked)}
            /> {label} {required && <React.Fragment> *</React.Fragment>}
        </label>
    );
}

export default Checkbox;

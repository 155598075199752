import React from 'react';

import styles from './text.module.scss';

const Text = ({
        children,
        inline,
        block
    }) => {
    return (
        <React.Fragment>
            {block || !block && !inline && <p className={styles.text}>{children}</p>}
            {inline && <span className={styles.text}>{children}</span>}
        </React.Fragment>
    );
}

export default Text;

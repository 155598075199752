import React from 'react';

import classNames from 'classnames';

import styles from './button.module.scss';

const Button = ({
        children,
        disabled,
        onClick,
        size,
        theme,
        type,
    }) => {
    return (
        <button
            disabled={disabled}
            type={type}
            onClick={() => { if (!type) onClick() }}
            className={classNames(styles.button, {
                [styles.purple]: undefined === theme || 'purple' === theme,
                [styles.small]: 'small' === size,
                [styles.medium]: undefined === size || 'medium' === size,
                [styles.large]: 'large' === size
            })}
        >
            {children}
        </button>
    );
}

export default Button;

import React, { useEffect, useState } from 'react';

import classNames from 'classnames';

import styles from './formControls.module.scss';

const Input = ({
        label,
        name,
        onChange,
        placeholder,
        required,
        theme,
        type,
        isValid,
        value,
    }) => {
    const [inputValue, setValue] = useState(undefined === value ? '' : value);

    useEffect(() => {
        onChange(inputValue);
    }, [inputValue]);

    return (
        <div
            className={classNames(styles.inputGroup, {
                [styles.default]: undefined === theme || 'default' === theme,
                [styles.purpleInverted]: 'purpleInverted' === theme,
                [styles.purple]: 'purple' === theme
            })}
        >
            {label && <label
                className={classNames(styles.label, {
                    [styles.invalidLabel]: !isValid
                })}
                htmlFor={name}
            >{label}{required && <React.Fragment> *</React.Fragment>}</label>}
            <input
                className={classNames(styles.input, {
                    [styles.invalidInput]: !isValid
                })}
                id={name}
                type={type}
                placeholder={placeholder}
                onChange={(e) => setValue(e.target.value)}
                value={inputValue}
            />
        </div>
    );
}

export default Input;

import React from 'react';

import styles from './headline.module.scss';

const Headline = ({
        children,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6
    }) => {
    return (
        <React.Fragment>
            {h1 && <h1 className={styles.headline}>{children}</h1>}
            {h2 && <h2 className={styles.headline}>{children}</h2>}
            {h3 && <h3 className={styles.headline}>{children}</h3>}
            {h4 && <h4 className={styles.headline}>{children}</h4>}
            {h5 && <h5 className={styles.headline}>{children}</h5>}
            {h6 && <h6 className={styles.headline}>{children}</h6>}
        </React.Fragment>
    );
}

export default Headline;

import React from 'react';

import styles from './spinner.module.scss';

const Spinner = () => {
    return (
        <div className={styles.spinner}>
            <div className={styles.inner1} />
            <div className={styles.inner2} />
        </div>
    );
}

export default Spinner;
